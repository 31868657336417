<template>
  <div class="school">
    <!--海报-->
    <div class="flex-box">
      <div class="banner" @click="scrollTo('intro')">
        <img
          v-lazy="require('@/assets/school/banner.png')"
          class="banner-img"
        />
      </div>
    </div>
    <!--区域导航-->
    <div class="tabs" ref="tabs">
      <div class="contain">
        <div
          v-for="tab in tabs"
          :key="tab.id"
          @click="scrollTo(tab.id)"
          class="tab"
        >
          {{ tab.name }}
        </div>
      </div>
    </div>
    <!--悬浮的区域导航-->
    <div class="tabs fixed" v-if="showTabHeader">
      <div class="contain">
        <div
          v-for="tab in tabs"
          :key="tab.id"
          @click="scrollTo(tab.id)"
          :class="['tab', tab.hover ? 'hover' : '']"
        >
          {{ tab.name }}
        </div>
      </div>
    </div>
    <!--方案介绍-->
    <div class="intro" ref="intro">
      <div class="title-box reveal">
        <div class="title1">青少年心理成长监测平台介绍</div>
        <div class="title2">
          以智能信息管理技术为依托，立足于大数据，通过推行“医教结合”心理健康服务体<br />系，为学校提供“科学、快速、有效”的一站式自助心理工作管理、服务平台。
        </div>
      </div>
      <div class="intro-contain">
        <div class="box reveal1">
          <img
            v-lazy="require('@/assets/school/intro-img1.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <img
                v-lazy="require('@/assets/school/intro-logo1.png')"
                class="logo"
              />
              <div class="box-title">心理健康监测机制</div>
            </div>
            <div class="box-intro">
              建立青少年心理健康监测机制，夯实青少年心理健康疏导责任。
            </div>
          </div>
        </div>
        <div class="box reveal2">
          <img
            v-lazy="require('@/assets/school/intro-img2.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <img
                v-lazy="require('@/assets/school/intro-logo2.png')"
                class="logo"
              />
              <div class="box-title">心理报告智能服务</div>
            </div>
            <div class="box-intro">
              为家庭、学校、社会提供“科学、精准、有效”的心理监测、评估、分析、跟踪服务。
            </div>
          </div>
        </div>
        <div class="box reveal3">
          <img
            v-lazy="require('@/assets/school/intro-img3.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <img
                v-lazy="require('@/assets/school/intro-logo3.png')"
                class="logo"
              />
              <div class="box-title">权威心理专家团队</div>
            </div>
            <div class="box-intro">
              平台咨询师团队专注于人际关系、个人成长等领域，向青少年量身定制最合适的心理健康方案。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--适用场景-->
    <div class="scene" ref="scene">
      <div class="title-box reveal">
        <div class="title1">青少年心理成长监测平台·应用场景</div>
        <div class="title2">家校服务智能化，心理危机早发现</div>
      </div>
      <div class="scene-contain">
        <div class="box reveal1">
          <img v-lazy="require('@/assets/school/scene1.png')" class="box-img" />
          <div class="box-main">
            <div class="box-title">
              为学校/教育系统提供青少年心理监测和筛查
            </div>
            <div class="box-intro">
              及时掌握青少年心理健康状况，为心理健康教育提供策略和措施，学生心理健康检测率达到100%。
            </div>
          </div>
        </div>
        <div class="box reveal2">
          <img v-lazy="require('@/assets/school/scene2.png')" class="box-img" />
          <div class="box-main">
            <div class="box-title">
              及时发现与解决校区辖区的学生心理问题
            </div>
            <div class="box-intro">
              及时发现学生心理问题并预警，做到早发现早治疗，为学生心理健康保健和预防工作提供家校同步。
            </div>
          </div>
        </div>
        <div class="box reveal3">
          <img v-lazy="require('@/assets/school/scene3.png')" class="box-img" />
          <div class="box-main">
            <div class="box-title">
              为学生/家长/学校提供心理成长档案管理
            </div>
            <div class="box-intro">
              建立青少年心理健康档案，对全体学生身心健康状况动态观察，推动学校心理健康信息化管理工作。
            </div>
          </div>
        </div>
        <div class="box reveal4">
          <img v-lazy="require('@/assets/school/scene4.png')" class="box-img" />
          <div class="box-main">
            <div class="box-title">
              为学校/教育系统建立心理健康危机干预机制
            </div>
            <div class="box-intro">
              可与公共卫生服务平台、全国学生健康监测预警网络平台无缝对接。对心理健康干预管理率达100%。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--专业性说明-->
    <div class="tool" ref="tool">
      <div class="title-box reveal">
        <div class="title1">
          专业服务青少年心理健康成长监测评估与指导工具
        </div>
      </div>
      <div class="tool-box reveal">
        <img
          v-lazy="require('@/assets/school/tool1.png')"
          class="tool-img revealImg"
        />
        <div class="tool-main">
          <div class="tool-content revealContent">
            <div class="flex">
              <img
                v-lazy="require('@/assets/school/tool-logo1.png')"
                class="logo1"
              />
              <div class="tool-title">
                国际权威量表，专业临床应用
              </div>
            </div>
            <div class="tool-intro">
              采用了国际权威通用的量表，结合国内大量临床实践，测试所根据的基础心理量表的框架，是评测青少年心理全方位指标的有效工具，也是专业心理咨询机构、三甲医院常用的诊断标准。
            </div>
          </div>
        </div>
      </div>
      <div class="tool-box reveal">
        <div class="tool-main">
          <div class="tool-content revealContent">
            <div class="flex">
              <img
                v-lazy="require('@/assets/school/tool-logo2.png')"
                class="logo2"
              />
              <div class="tool-title">
                数据可视化，享全年监测服务
              </div>
            </div>
            <div class="tool-intro">
              全年实时关注心理健康状况，生成全年动态心理档案。智能监测提醒，根据每次结果科学定制下次测评时间。
            </div>
          </div>
        </div>
        <img
          v-lazy="require('@/assets/school/tool2.png')"
          class="tool-img revealImg"
        />
      </div>
    </div>
    <!--评测结果展示-->
    <div class="report" ref="report">
      <div class="title-box reveal">
        <div class="title1">面向学校管理人员输出整体分析报告</div>
        <div class="title2">
          心理专家出具整体综合分析报告，可选开放学生家长即时查看报告
        </div>
      </div>
      <div class="report-contain">
        <div class="box reveal1">
          <div class="box-title title-bg1">
            <div style="background: #fafbff">身体健康情况评估</div>
          </div>
          <div class="box-intro">
            12大方面定位你的身体各项状况，精细化分析症状表现，快速筛查潜在健康风险。
          </div>
          <img
            v-lazy="require('@/assets/school/report-img1.png')"
            class="box-img1"
          />
        </div>
        <div class="box reveal2">
          <div class="box-title title-bg2">
            <div style="background: #fafbff">心理健康情况评估</div>
          </div>
          <div class="box-intro">
            从6大方面评估你的精神心理状态，详细解析对应的心理倾向，准确筛查潜在精神层面风险。
          </div>
          <img
            v-lazy="require('@/assets/school/report-img2.png')"
            class="box-img2"
          />
        </div>
        <div class="box reveal3">
          <div class="box-title title-bg3">
            <div style="background: #fafbff">睡眠质量分析评估</div>
          </div>
          <div class="box-intro">
            从你的睡眠质量、夜间习惯、个人主观思想三方面分析睡眠等级，寻找有效方法。
          </div>
          <img
            v-lazy="require('@/assets/school/report-img3.png')"
            class="box-img3"
          />
        </div>
        <div class="box reveal4">
          <div class="box-title title-bg4">
            <div style="background: #fafbff">心理防御能力评估</div>
          </div>
          <div class="box-intro">
            评估心理防御能力综合情况，4个方面，24个类型来剖析你较常使用哪一种防御机制。
          </div>
          <img
            v-lazy="require('@/assets/school/report-img4.png')"
            class="box-img4"
          />
        </div>
      </div>
    </div>
    <!--申请试用-->
    <div class="test" ref="test">
      <div class="title-box reveal">
        <div class="title1">申请免费试用</div>
        <div class="title2">填写信息，立即开通体验</div>
      </div>
      <img
          v-lazy="require('@/assets/school/Group 501.png')"
          style="height:600px;width:1200px"
          class="revealImg"
          @click="gotoLogin"
        />
    </div>
    <!-- <div style="font-size:35px; width:950px; height:50px; text-align:center; font-weight:bold;" >浙ICP备2022026803号</div> -->

  </div>
</template>

<script>
import scrollReveal from "scrollreveal";
export default {
  name: "school",
  data() {
    return {
      tabs: [
        {
          name: "方案介绍",
          id: "intro",
          hover: false,
        },
        {
          name: "适用场景",
          id: "scene",
          hover: false,
        },
        {
          name: "专业性说明",
          id: "tool",
          hover: false,
        },
        {
          name: "测评报告解读",
          id: "report",
          hover: false,
        },
        {
          name: "申请试用",
          id: "test",
          hover: false,
        },
      ],
      showTabHeader: false,
      scrollReveal: new scrollReveal(),
    };
  },
  mounted() {
    this.getReveal();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll: function() {
      this.$nextTick(() => {
        let top = this.$refs.tabs.getBoundingClientRect().top;
        this.showTabHeader = top <= 0 ? true : false;
        if (top <= 0) {
          this.tabs.forEach((tab) => {
            let tabData = this.$refs[tab.id].getBoundingClientRect();
            if (
              Math.floor(tabData.top) <= 0 &&
              Math.floor(tabData.height + tabData.top) > 0
            ) {
              tab["hover"] = true;
            } else {
              tab["hover"] = false;
            }
          });
        }
      });
    },
    scrollTo: function(name) {
      this.$nextTick(() => {
        this.$refs[name].scrollIntoView({ block: "start", behavior: "smooth" });
      });
    },
    gotoLogin() {
      this.$router.push("/login");
    },
    getReveal() {
      let animation = {
        duration: 1000,
        reset: false,
        mobile: false,
        opacity: 0,
        easing: "ease-in-out",
        enter: "bottom",
        distance: "40px",
      };
      let setReveal = (className, delay) => {
        let revealAnimation = JSON.parse(JSON.stringify(animation));
        revealAnimation["delay"] = delay;
        this.scrollReveal.reveal(className, revealAnimation);
      };
      setReveal(".reveal", 0);
      setReveal(".revealImg", 500);
      setReveal(".revealContent", 800);
      setReveal(".reveal1", 200);
      setReveal(".reveal2", 500);
      setReveal(".reveal3", 800);
      setReveal(".reveal4", 1100);
    },
  },
};
</script>
<style scoped lang="scss">
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.school {
  width: 100%;
  background: #fff;

  .title-box {
    @include flex($direction: column, $justify: center);
    text-align: center;
    color: $title-color;
    .title1 {
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .title2 {
      font-size: 24px;
      line-height: 33px;
    }
  }

  .banner {
    @include flex($direction: column);
    width: 100%;
    height: 500px;
    overflow: hidden;
    background-image: url("~@/assets/school/banner-bg.png");
    background-size: 100% 500px;
    background-repeat: no-repeat;
    background-color: #fff;

    .banner-img {
      width: 1920px;
      height: 500px;
    }
  }

  .tabs {
    width: 100%;
    height: 60px;
    background: #f0f1f7;
    @include flex($justify: center);
    .contain {
      width: $width;
      height: 100%;
      @include flex($justify: space-around);
      .tab {
        font-size: 16px;
        color: #414246;
      }
    }
  }

  .fixed {
    background-color: #f5f6f8;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    height: 60px;
    .tab {
      position: relative;
      padding: 20px 0;
    }
    .tab::after {
      position: absolute;
      content: "";
      bottom: 1px;
      left: 50%;
      width: 0;
      height: 3px;
      border-radius: 30px;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background-color: $color;
      -webkit-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
    }
    .tab.hover {
      color: $color;
    }
    .tab:hover {
      color: $color;
    }
    .tab:hover::after {
      width: 100%;
    }
  }

  .intro {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    .title-box {
      margin-bottom: 60px;
    }
    .intro-contain {
      width: $width;
      @include flex($justify: space-between);
      .box {
        width: 390px;
        height: 430px;
        border-radius: 4px;
        overflow: hidden;
        background: #fafbff;
        .box-img {
          width: 390px;
          height: 220px;
        }
        .box-main {
          padding: 30px 40px;
          .logo {
            width: 60px;
            height: 60px;
            margin-right: 20px;
            flex-shrink: 0;
          }
          .flex {
            @include flex;
          }
          .box-title {
            color: $title-color;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
          }
          .box-intro {
            color: $intro-color;
            font-size: 16px;
            line-height: 24px;
            margin-top: 20px;
          }
        }
      }
      .box:hover {
        box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
      }
    }
  }

  .scene {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    .title-box {
      margin-bottom: 60px;
    }
    .scene-contain {
      width: $width;
      @include flex($justify: space-between);
      flex-wrap: wrap;
      .box {
        width: 590px;
        height: 448px;
        border-radius: 4px;
        overflow: hidden;
        background: #fafbff;
        margin-bottom: 20px;
        .box-img {
          width: 590px;
          height: 280px;
        }
        .box-main {
          padding: 30px 30px 40px 30px;
          .box-title {
            color: $title-color;
            font-size: 24px;
            font-weight: 500;
            line-height: 33px;
          }
          .box-intro {
            color: $intro-color;
            font-size: 16px;
            line-height: 24px;
            margin-top: 20px;
          }
        }
      }
      .box:hover {
        box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
      }
    }
  }

  .tool {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    .title-box {
      margin-bottom: 60px;
    }
    .tool-box {
      width: $width;
      height: 350px;
      background: #fafbff;
      border-radius: 4px;
      padding: 40px;
      @include flex($justify: space-between);
      .tool-img {
        width: 480px;
        height: 270px;
      }
      .tool-main {
        flex: 1;
        @include flex($direction: column, $justify: center);
        .tool-content {
          max-width: 520px;
          .flex {
            @include flex;
          }
          .logo1 {
            width: 26px;
            height: 32px;
            margin-right: 20px;
          }
          .logo2 {
            width: 30px;
            height: 30px;
            margin-right: 20px;
          }
          .tool-title {
            font-size: 24px;
            color: $title-color;
            line-height: 33px;
            font-weight: 500;
          }
          .tool-intro {
            color: $intro-color;
            font-size: 16px;
            line-height: 24px;
            margin-top: 20px;
          }
        }
      }
    }
    .tool-box:not(:last-of-type) {
      margin-bottom: 20px;
    }
    .tool-box:hover {
      box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
    }
  }

  .report {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 60px;
    }
    .report-contain {
      width: $width;
      @include flex($justify: space-between);
      .box {
        @include flex($direction: column);
        width: 288px;
        height: 448px;
        border-radius: 4px;
        background: #fafbff;
        padding: 20px 24px;
        .box-title {
          @include flex($direction: column, $justify: center);
          width: 100%;
          height: 156px !important;
          font-size: 24px;
          color: #596df8;
          font-weight: 500;
          background-color: #fafbff;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 76px 156px !important;
        }
        .title-bg1 {
          background-image: url("~@/assets/school/title-bg1.png");
        }
        .title-bg2 {
          background-image: url("~@/assets/school/title-bg2.png");
        }
        .title-bg3 {
          background-image: url("~@/assets/school/title-bg3.png");
        }
        .title-bg4 {
          background-image: url("~@/assets/school/title-bg4.png");
        }
        .box-intro {
          font-size: 16px;
          color: $intro-color;
          line-height: 24px;
        }
        .box-img1 {
          width: 178px;
          height: 178px;
        }
        .box-img2 {
          width: 151px;
          height: 151px;
          margin-top: 20px;
        }
        .box-img3 {
          width: 161px;
          height: 161px;
          margin-top: -10px;
        }
        .box-img4 {
          width: 189px;
          height: 189px;
        }
      }
      .box:hover {
        box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
      }
    }
  }

  .test {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 40px;
    }
    .test-img {
      width: $width;
      height: 600px;
    }
  }
}
</style>
